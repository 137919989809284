// eslint-disable-next-line
export default (state, action) => {
  switch (action.type) {
    case 'DELETE_PHOTO':
      return {
        ...state,
        files: state.files.filter(file => file.fileId !== action.payload)
      };
    case 'RENAME_PHOTO':
      const newFiles = state.files.map(file => {
        if (file.fileId === action.payload.fileId) {
          const updatedPhoto = {
            ...file,
            fileName: action.payload.fileName
          };
          return updatedPhoto;
        }
        return file;
      });
      return { ...state, files: newFiles };
    case 'ADD_PHOTO':
      return {
        ...state,
        files: [...state.files, action.payload]
      };
    case 'DELETE_NOTE':
      return {
        ...state,
        notes: state.notes.filter(note => note.noteId !== action.payload)
      };
    case 'ADD_NOTE':
      return {
        ...state,
        notes: [...state.notes, action.payload]
      };
    default:
      return state;
  }
};
