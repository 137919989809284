import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import StartPage from './StartPage';
import AddPhotos from './AddPhotos';
import EndPage from './EndPage';
import SentBefore from './SentBefore';
import LinkExpired from './LinkExpired';
import Error from './Error';

function Routes() {
  return (
    <Router>
      <div>
        <Switch>
          <Route path='/' exact component={StartPage} />
          <Route path='/uploadPhotos' component={AddPhotos} />
          <Route path='/end' component={EndPage} />
          <Route path='/errorSent' component={SentBefore} />
          <Route path='/errorExpired' component={LinkExpired} />
          <Route path='/error' component={Error} />
        </Switch>
      </div>
    </Router>
  );
}

export default Routes;
