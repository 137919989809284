import React from 'react';
import ReactDOM from 'react-dom';

import StartPage from './StartPage';
import Routes from './Routes';

ReactDOM.render(
  <Routes>
    <StartPage />
  </Routes>,
  document.getElementById('root')
);
