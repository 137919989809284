import React from 'react';
import { Box, Card, CardBody, CardFooter, Grommet, Text } from 'grommet';
import theme from './theme';
import './styles.css';
import 'react-html5-camera-photo/build/css/index.css';
import { GlobalProvider } from './context/GlobalState';

import { SBHeader } from './components/SBHeader';
import { SBFooter } from './components/SBFooter';
import { PhotoList } from './components/PhotoList';
import { AddPhoto } from './components/AddPhoto';
import { NoteList } from './components/NoteList';
import { AddNote } from './components/AddNote';
import { SendPhotos } from './components/SendPhotos';

const url = new URL(window.location.href);
const useNote = url.searchParams.get('useNote');

function AddPhotos() {
  const title = useNote && useNote === 'true' ? 'Send your photos/notes' : 'Send your photos';
  const subtitle = 'We accept JPG and PNG files under 10MB. You can upload a max of 5 photos.';
  const note = 'Don’t worry—we use encryption to protect your photos and keep your information safe.';

  return (
    <GlobalProvider>
      <Grommet theme={theme}>
        <SBHeader />
        <Box align='center'>
          <Box align='start'>
            <Text margin='small' size='xlarge'>
              {title}
            </Text>
            <Text margin='small' size='medium'>
              {subtitle}
            </Text>
          </Box>
          <PhotoList />
          <Box>
            <AddPhoto />
          </Box>
          {useNote && (
            <>
              <Card width='medium'>
                <CardBody>
                  <NoteList />
                </CardBody>
                <CardFooter>
                  <AddNote />
                </CardFooter>
              </Card>
              <Card pad='small' gap='medium' background='accent-2'>
                <Text>{note}</Text>
              </Card>
            </>
          )}
          <SendPhotos />
        </Box>
        <SBFooter />
      </Grommet>
    </GlobalProvider>
  );
}

export default AddPhotos;
