import React, { useRef, useState } from 'react';
import { decode as base64_decode } from 'base-64';
import { Grommet, Text, Image, Box, Header } from 'grommet';
import theme from './theme';
import './styles.css';

import { SBHeader } from './components/SBHeader';
import { SBFooter } from './components/SBFooter';

import iceCreamCone from './assets/Ice cream cone.svg';

function LinkExpired() {
  const url = new URL(window.location.href);
  let spContact = base64_decode(url.searchParams.get('spContact'));
  const willMount = useRef(true);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('Something went wrong—try again.');
  const title = 'Oh no, this linked expired!';
  const subtitle = 'Unfortunately, links are only good for 24 hours. Don’t worry, you can get a new one—just call ';

  async function validateLink() {
    if (!url.searchParams.get('spName') || !url.searchParams.get('spContact')) {
      setError(true);
      setErrorMessage('The link used is not valid.');
    }
  }

  if (willMount.current) {
    validateLink();
    willMount.current = false;
  }

  return (
    <Grommet theme={theme}>
      <SBHeader />
      {error && (
        <Header background='accent-4' style={{ lineHeight: '1erm' }}>
          <Text margin='small' size='small'>
            {errorMessage}
          </Text>
        </Header>
      )}
      <Box align='center'>
        <Box align='start'>
          <Text margin='small' size='xlarge'>
            {title}
          </Text>
          <Text margin='small' size='medium'>
            {subtitle}
            <span style={{ color: '#8223d2' }}>
              <u>{spContact}</u>
            </span>
            .
          </Text>
        </Box>
        <Box pad='small'>
          <Image src={iceCreamCone} a11yTitle='Ice-cream cone'></Image>
        </Box>
      </Box>
      <SBFooter />
    </Grommet>
  );
}

export default LinkExpired;
