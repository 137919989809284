import React, { useRef, useState } from 'react';
import { Grommet, Text, Box, Image, Header } from 'grommet';
import theme from './theme';
import { decode as base64_decode } from 'base-64';
import './styles.css';
import { GlobalProvider } from './context/GlobalState';

import { SBHeader } from './components/SBHeader';
import { SBFooter } from './components/SBFooter';
import airplane from './assets/Airplane.svg';

function EndPage() {
  const url = new URL(window.location.href);
  const useNote = url.searchParams.get('useNote');
  const title = 'You’re all set!';
  let spName = base64_decode(url.searchParams.get('spName'));
  let spContact = base64_decode(url.searchParams.get('spContact'));
  const willMount = useRef(true);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('Something went wrong—try again.');
  const subtitle =
    useNote && useNote === 'true'
      ? 'You successfully sent the photos/notes—there’s nothing more you need to do.'
      : 'You successfully sent the photos—there’s nothing more you need to do.';
  const note = 'If you have any questions, feel free to reach out to ' + spName + ' at ';

  async function validateLink() {
    if (!url.searchParams.get('spName') || !url.searchParams.get('spContact')) {
      setError(true);
      setErrorMessage('The link used is not valid.');
    }
  }

  if (willMount.current) {
    validateLink();
    willMount.current = false;
  }

  return (
    <GlobalProvider>
      <Grommet theme={theme}>
        <SBHeader />
        {error && (
          <Header background='accent-4' style={{ lineHeight: '1erm' }}>
            <Text margin='small' size='small'>
              {errorMessage}
            </Text>
          </Header>
        )}
        <Box align='center'>
          <Box align='start'>
            <Text margin='small' size='xlarge'>
              {title}
            </Text>
            <Text margin='small' size='medium'>
              {subtitle}
            </Text>
          </Box>
          <Box align='center' pad='medium'>
            <Image src={airplane} a11yTitle='Airplane'></Image>
          </Box>
          <Box
            align='center'
            pad='small'
            style={{ borderRadius: 8, marginTop: 10, marginBottom: 5, flexDirection: 'row', justifyContent: 'space-between' }}
            gap='medium'
            background='accent-2'
          >
            <Text>
              {note}
              <strong>
                <u>{spContact}</u>
              </strong>
              .
            </Text>
            &nbsp;&nbsp;
            {/* <Image src={airplane} width='30' height='30' a11yTitle="Airplane"></Image> */}
          </Box>
        </Box>
        <SBFooter />
      </Grommet>
    </GlobalProvider>
  );
}

export default EndPage;
