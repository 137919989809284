import React, { useContext, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { GlobalContext } from '../context/GlobalState';
import { Box, Button, Card, Text } from 'grommet';
import uuid from 'react-uuid';
import config from '../config.json';
import { Close } from 'grommet-icons';

const url = new URL(window.location.href);
let id = url.searchParams.get('id');
let extref = url.searchParams.get('extref');
const correlationId = uuid();

export const SendPhotos = () => {
  const [sent, setSent] = useState(false);
  const [error, setError] = useState('');
  const { files, notes } = useContext(GlobalContext);

  async function uploadPhotosNotes(files, notes) {
    if ((files && files.length > 0) || (notes && notes.length > 0)) {
      let response = await storePhotosNotes(id, extref, files, notes);
      /*            if (response.status === 200) {
                setSent(true);
            } else {
                setError("Oh no, there was a problem sending your photos. Try again.");
            }*/

      //console.log("response.status: " + response.status);
      let output = (await response.json()).body;
      //console.log("response output: " + JSON.stringify(output, null, 2));
      if (output.status === 'SUCCESS') {
        setSent(true);
      } else {
        if (notes && notes.length > 0) {
          setError('Oh no, there was a problem sending your photos/notes. Try again.');
        } else {
          setError('Oh no, there was a problem sending your photos. Try again.');
        }
      }
    }
  }

  function getconfigObject(name) {
    const endpoint = config.API.endpoints.filter(ep => ep.name.toLowerCase() === name.toLowerCase());
    return endpoint;
  }

  async function storePhotosNotes(id, extref, files, notes) {
    const configObject = getconfigObject('remoteviewer-files');
    //console.log(`${configObject[0].endpoint}${configObject[0].uri}`);
    //console.log(` ${configObject[0].apikey}`);
    console.log('correlationId - ');
    console.log(correlationId);

    //console.log("!!!!send photos request:");
    //console.log(request);

    let response = await fetch(`${configObject[0].endpoint}${configObject[0].uri}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        correlationId: correlationId,
        sessionId: uuid(),
        username: 'servicebench',
        sender: 'sbappuser',
        companyId: 'system',
        'x-api-key': configObject[0].apikey
      },
      body: JSON.stringify({
        interactionId: id,
        externalReferenceId: extref,
        files: files,
        notes: notes
      })
    });

    //console.log("storePhotosNotes response", response);
    return response;
  }

  if (sent) {
    return (
      <Redirect
        to={{
          pathname: '/end/?spName=' + url.searchParams.get('spName') + '&spContact=' + url.searchParams.get('spContact')
        }}
      />
    );
  }

  return (
    <>
      {error !== '' && (
        <Card background='accent-4' gap='small'>
          <Box direction='row' align='center'>
            <Text margin='small' size='large'>
              <b>{error}</b>
            </Text>
            <Button
              plain
              icon={<Close />}
              a11yTitle='close'
              onClick={() => {
                setError('');
              }}
              margin='small'
            />
          </Box>
        </Card>
      )}
      {
        <Box align='center' pad='medium'>
          <Button
            primary
            label='Get Started'
            onClick={() => {
              uploadPhotosNotes(files, notes);
            }}
            margin='small'
            disabled={!(files.length > 0 || notes.length > 0)}
          />
        </Box>
      }
    </>
  );
};
