import React from 'react';

import { Grommet, Text, Box } from 'grommet';
import theme from './theme';
import './styles.css';

import { SBHeader } from './components/SBHeader';
import { SBFooter } from './components/SBFooter';

function Error() {
  const title = 'Oh no, error happened!';
  return (
    <Grommet theme={theme}>
      <SBHeader />
      <Box align='center'>
        <Box align='start'>
          <Text margin='small' size='xlarge'>
            {title}
          </Text>
        </Box>
      </Box>
      <SBFooter />
    </Grommet>
  );
}

export default Error;
