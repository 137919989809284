import React from 'react';

import { Header } from 'grommet';
import serviceBenchLogo from '../assets/logo-white.svg';

const url = new URL(window.location.href);
const themeParameter = url.searchParams.get('theme');

export const SBHeader = () => {
  return (
    <>
      {!themeParameter && (
        <Header background='accent-1' pad='small'>
          <img src={serviceBenchLogo} style={{ padding: '0px' }} width='600' alt='ServiceBench logo' />
        </Header>
      )}
      {themeParameter && themeParameter === 'asurionpurple' && (
        <Header
          background='accent-3'
          pad='small'
          border={[
            {
              color: 'accent-8',
              size: 'medium',
              style: 'solid',
              side: 'top'
            },
            {
              color: 'accent-5',
              size: 'small',
              style: 'solid',
              side: 'bottom'
            }
          ]}
        >
          <img
            width='154'
            height='36'
            loading='lazy'
            className='asurionLogo w-28 sm:w-32 xl:w-36'
            src='data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyMjAuMiA1MS4yIj48cGF0aCBkPSJNMTAyLjQgMTQuOGgtNy45djE5LjljMCA1LjMtMi43IDkuMS03LjkgOS4xcy03LjgtMi43LTcuOC05LjFWMTQuOGgtNy45djIxLjZjMCA5LjYgNC45IDE0LjggMTMuOCAxNC44IDUgMCA4LjItMi40IDkuOS00LjV2NC4xaDcuOWwtLjEtMzZ6bTYyLjYtLjRjLTEwLjIgMC0xOC40IDguMy0xOC40IDE4LjQgMCAxMC4yIDguMyAxOC40IDE4LjQgMTguNCAxMC4yIDAgMTguNC04LjMgMTguNC0xOC40LjEtMTAuMi04LjItMTguNC0xOC40LTE4LjR6bTEwLjYgMTguNGMwIDUuOC00LjcgMTAuNS0xMC41IDEwLjVzLTEwLjUtNC43LTEwLjUtMTAuNSA0LjctMTAuNSAxMC41LTEwLjUgMTAuNSA0LjcgMTAuNSAxMC41em0tNDcuOC0xOC40Yy0yLjkgMC03LjIuNi0xMC41IDQuMnYtMy44aC03Ljl2MzZoNy45VjMwYzAtNC4zIDMuNS03LjcgNy43LTcuNyAxIDAgMS45LjIgMi44LjV2LTguNHpNNjIuNiA0Ny43Yy0yLjMgMi4zLTUuMyAzLjUtOC42IDMuNS0zLjIgMC02LjMtMS4zLTguNi0zLjUtMi4zLTIuMy0zLjUtNS4zLTMuNS04LjZoNy41YzAgMS4yLjUgMi40IDEuMyAzLjIuOS45IDIgMS4zIDMuMiAxLjNzMi40LS41IDMuMi0xLjNjMS44LTEuOCAxLjgtNC42LjEtNi40LTEuMS0uOS0xLjktMS0zLjMtMS4zLTEuOS0uNC00LjQtLjktNy0zLjUtMy44LTMuOC0zLjgtMTAuMSAwLTEzLjkgMy44LTMuOCAxMC4xLTMuOCAxMy45IDAgLjQuNC43LjggMS4xIDEuM2wtNSA1Yy0uOC0uOS0xLjUtMS40LTIuNS0xLjUtLjgtLjItMS42IDAtMi4xLjYtLjQuNC0uNyAxLS43IDEuNnMuMiAxLjIuNyAxLjZjMSAxIDEuNyAxLjIgMy4yIDEuNCAxLjcuMyA0LjEuOCA2LjggMy4xbC4yLjJjNC44IDQuOCA0LjggMTIuNS4xIDE3LjJ6bS0zMy43IDMuMWg3Ljl2LTE4YzAtMTAuMi04LjMtMTguNC0xOC40LTE4LjRTMCAyMi42IDAgMzIuOHM4LjMgMTguNCAxOC40IDE4LjRjMy41IDAgNy40LTEgMTAuNS00LjV2NC4xem0tMTAuNS03LjVjLTUuOCAwLTEwLjUtNC43LTEwLjUtMTAuNXM0LjctMTAuNSAxMC41LTEwLjVTMjguOSAyNyAyOC45IDMyLjhzLTQuNyAxMC41LTEwLjUgMTAuNXptMTg2LTIxYy00LjQgMC03LjkgMy41LTcuOSA3Ljl2MjAuNmgtNy45VjMwLjJjMC04LjcgNy4xLTE1LjggMTUuOC0xNS44czE1LjggNy4xIDE1LjggMTUuOHYyMC42aC03LjlWMzAuMmMwLTQuNC0zLjUtNy45LTcuOS03Ljl6bS03MC44LTcuNWg3Ljl2MzZoLTcuOXYtMzZ6bTkuMy05LjRjMCAzLTIuNCA1LjQtNS40IDUuNHMtNS40LTIuNC01LjQtNS40IDIuNC01LjQgNS40LTUuNCA1LjQgMi40IDUuNCA1LjR6Ii8+PC9zdmc+'
            alt='Asurion Desktop Logo'
          ></img>
        </Header>
      )}{' '}
    </>
  );
};
