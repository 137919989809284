import React from 'react';

import { Anchor, Box, Footer, Text, Image } from 'grommet';
import fb from '../assets/fb.svg';
import twitter from '../assets/twitter.svg';
import linkedIn from '../assets/linkedIn.svg';
import mailcontact from '../assets/mail-contact.svg';
import mobile from '../assets/mobile.svg';

const url = new URL(window.location.href);
const themeParameter = url.searchParams.get('theme');
const backgroundColor = themeParameter && themeParameter === 'asurionpurple' ? 'accent-5' : 'accent-1';

const imageWidthHeight = { width: 20, height: 20 };
const imageWithText = { fontSize: 16, color: '#000', fontWeight: 'normal', marginTop: -5 };
const termsPrivacyLink = { fontSize: 16, fontWeight: 'normal', color: '#000', textDecoration: 'underline' };
const twoLineHight = { lineHeight: '2.5rem' };

export const SBFooter = () => (
  <Box style={{ borderTop: '1px solid #D5D6DA' }}>
    <Footer background={backgroundColor} pad='small' justify='center'>
      <Box align='left'>
        <Box direction='row' gap='medium'>
          <div style={{ lineHeight: '1rem' }}>
            <Text style={{ fontWeight: 'bold', fontSize: 24 }}>
              asurion &nbsp;
              <br />
            </Text>
            <span style={{ fontSize: 14 }}>© Asurion 1992-2021. All rights reserved.</span>
          </div>
        </Box>
        <Box direction='row' gap='medium' style={twoLineHight}>
          <Anchor style={termsPrivacyLink} label='Terms of Use' href='https://www.asurion.com/terms-conditions/' />
          <Anchor style={termsPrivacyLink} label='Privacy Policy' href='https://www.asurion.com/privacy-policy/' />
        </Box>
        <Box direction='row' gap='medium' style={twoLineHight}>
          <Anchor href='mailto:servicebenchhelpmail@asurion.com' target='_blank'>
            <Image src={mailcontact} style={imageWidthHeight} a11yTitle='mailcontact'></Image>
          </Anchor>
          <Anchor style={imageWithText} label='Contact Us' href='mailto:servicebenchhelpmail@asurion.com' target='_blank' />
          <Anchor href='/servicebenchv5/common/mobilePage.action' target='_blank'>
            <Image src={mobile} style={imageWidthHeight} a11yTitle='mobile'></Image>
          </Anchor>
          <Anchor style={imageWithText} label='Mobile App' href='/servicebenchv5/common/mobilePage.action' target='_blank' />
        </Box>
        <Box direction='row' gap='medium' style={twoLineHight}>
          <Anchor href='http://www.facebook.com/servicebench1' target='_blank'>
            <Image src={fb} style={imageWidthHeight} a11yTitle='fb'></Image>
          </Anchor>
          <Anchor href='http://www.twitter.com/servicebench1' target='_blank'>
            <Image src={twitter} style={imageWidthHeight} a11yTitle='twitter'></Image>
          </Anchor>
          <Anchor href='http://www.linkedin.com/company/32718?trk=prof-0-ovw-curr_pos' target='_blank'>
            <Image src={linkedIn} style={imageWidthHeight} a11yTitle='linkedIn'></Image>
          </Anchor>
        </Box>
      </Box>
    </Footer>
  </Box>
);
