import React, { useContext, useState } from 'react';
import uuid from 'react-uuid';
import { GlobalContext } from '../context/GlobalState';
import { Box, Button, Text, TextArea } from 'grommet';
import { Close } from 'grommet-icons';

export const AddNote = () => {
  const [error, setError] = React.useState('');
  const { addNote } = useContext(GlobalContext);
  const [value, setValue] = useState('');

  const onNotesAdded = event => {
    setError('');
    if (value === '') {
      setError('Please enter a note before adding it.');
      return false;
    }
    const note = {
      noteId: uuid(),
      noteContent: value
    };
    addNote(note);
    setValue('');
  };

  return (
    <>
      {
        <Box direction='column' width='medium' pad='small'>
          {error && (
            <Box direction='row' align='center' background='accent-4'>
              <Text margin='small' size='medium'>
                {error}
              </Text>
              <Button
                plain
                icon={<Close />}
                onClick={() => {
                  setError(undefined);
                }}
                margin='small'
              />
            </Box>
          )}
          <TextArea value={value} onChange={event => setValue(event.target.value)} placeholder='Optionally add note here...' fill />
          <Box justify='center' align='center' pad='small'>
            <Button label='Add Note' onClick={onNotesAdded} />
          </Box>
        </Box>
      }
    </>
  );
};
