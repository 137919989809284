import React, { useRef, useState } from 'react';
import { Grommet, Text, Card, Image, Box, Header } from 'grommet';
import { decode as base64_decode } from 'base-64';
import theme from './theme';
import './styles.css';

import { SBHeader } from './components/SBHeader';
import { SBFooter } from './components/SBFooter';

import transfer from './assets/Transfer.svg';

function SentBefore() {
  const url = new URL(window.location.href);
  let spName = base64_decode(url.searchParams.get('spName'));
  let spContact = base64_decode(url.searchParams.get('spContact'));
  const willMount = useRef(true);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('Something went wrong—try again.');
  const title = 'Oops, you already did this.';
  const subtitle = 'Our records show that you sent your photos Before.';
  const note = 'If you think this is an error or need to send additional photos, contact ' + spName + ' at ';

  async function validateLink() {
    if (!url.searchParams.get('spName') || !url.searchParams.get('spContact')) {
      setError(true);
      setErrorMessage('The link used is not valid.');
    }
  }

  if (willMount.current) {
    validateLink();
    willMount.current = false;
  }

  return (
    <Grommet theme={theme}>
      <SBHeader />
      {error && (
        <Header background='accent-4' style={{ lineHeight: '1erm' }}>
          <Text margin='small' size='small'>
            {errorMessage}
          </Text>
        </Header>
      )}
      <Box align='center'>
        <Box align='start'>
          <Text margin='small' size='xlarge'>
            {title}
          </Text>
          <Text margin='small' size='medium'>
            {subtitle}
          </Text>
        </Box>
        <Box pad='small'>
          <Image src={transfer} a11yTitle='Transfer'></Image>
          <Card pad='small' background='accent-2'>
            <Text margin='small' size='small'>
              {note}
              <strong>
                <u>{spContact}</u>
              </strong>
              .
            </Text>
          </Card>
        </Box>
      </Box>
      <SBFooter />
    </Grommet>
  );
}

export default SentBefore;
