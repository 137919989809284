import { grommet } from 'grommet/themes';
import { deepMerge } from 'grommet/utils';

const url = new URL(window.location.href);
const themeParameter = url.searchParams.get('theme');
const color = themeParameter && themeParameter === 'asurionpurple' ? '#8223d2' : '#8223D2';

const theme = deepMerge(grommet, {
  global: {
    colors: {
      brand: color,
      'accent-1': '#FFFFFF',
      'accent-2': '#F0F0F5',
      'accent-3': '#FFFFFF',
      'accent-4': '#DB1B1B',
      'accent-5': '#E6E6EB',
      'accent-6': '#51b5e0',
      'accent-7': '#ecf4f7',
      'accent-8': '#8223d2',
      white: '#FFFFFF',
      black: '#000000',
      focus: '#FFFFFF'
    },
    text: {
      align: 'center',
      dark: '#000000',
      light: '#FFFFFF'
    },
    font: {
      family: 'Apercu Pro', // 'Roboto',
      size: '1em', // '14px',
      height: '4rem', // '20px',
      style: 'normal'
    },
    button: {
      default: {
        color: { color },
        border: undefined,
        padding: {
          horizontal: '12px',
          vertical: '8px'
        }
      },
      primary: {
        background: { color: 'brand' },
        border: undefined,
        color: 'text-strong',
        font: { weight: 'bold' },
        padding: {
          horizontal: '12px',
          vertical: '8px'
        }
      },
      secondary: {
        border: { color: 'brand', width: '2px' },
        color: 'brand',
        padding: {
          horizontal: '8px',
          vertical: '4px'
        }
      },
      plain: {
        border: {},
        color: 'text',
        padding: {
          horizontal: '8px',
          vertical: '4px'
        }
      }
    }
  }
});

export default theme;
