import React, { createContext, useReducer } from 'react';
import AppReducer from './AppReducer';

// Initial state
const initialState = {
  files: [],
  notes: []
};

// Create context
export const GlobalContext = createContext(initialState);

// Provider component
export const GlobalProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AppReducer, initialState);

  // Actions
  function deletePhoto(id) {
    dispatch({
      type: 'DELETE_PHOTO',
      payload: id
    });
  }

  function renamePhoto(file) {
    dispatch({
      type: 'RENAME_PHOTO',
      payload: file
    });
  }

  function addPhoto(file) {
    dispatch({
      type: 'ADD_PHOTO',
      payload: file
    });
  }

  function deleteNote(id) {
    dispatch({
      type: 'DELETE_NOTE',
      payload: id
    });
  }

  function addNote(note) {
    dispatch({
      type: 'ADD_NOTE',
      payload: note
    });
  }

  return (
    <GlobalContext.Provider
      value={{
        files: state.files,
        notes: state.notes,
        error: state.error,
        deletePhoto,
        addPhoto,
        renamePhoto,
        deleteNote,
        addNote
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
