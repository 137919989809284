import React, { useContext } from 'react';
import { GlobalContext } from '../context/GlobalState';
import { Box, Image, Button, Text, Card, CardBody, Layer, FormField, TextInput } from 'grommet';
import { Close } from 'grommet-icons';

export const Photo = ({ file }) => {
  const { deletePhoto, renamePhoto } = useContext(GlobalContext);
  const [open, setOpen] = React.useState(false);
  const [error, setError] = React.useState('');
  let [newPhotoName, setNewPhotoName] = React.useState(file.fileName);

  const onOpen = () => {
    setNewPhotoName(file.fileName);
    setOpen(true);
  };
  const onCancel = () => {
    setOpen(undefined);
    setError(undefined);
  };
  const onChange = event => {
    setNewPhotoName(event.target.value);
  };
  const onClose = event => {
    event.preventDefault();
    if (newPhotoName === '') {
      setOpen(true);
      setError('Missing photo name. Add it and try again.');
    } else {
      // eslint-disable-next-line
      const specialChars = /[`!@#$%^&*()+\=\[\]{};':"\\|,<>\/?~]/;
      if (newPhotoName.trim() === '' || specialChars.test(newPhotoName)) {
        setOpen(true);
        setError('Photo name is invalid. Please try again.');
      } else if (newPhotoName.includes(`  `) || newPhotoName.includes(`__`) || newPhotoName.includes(`--`) || newPhotoName.includes(`..`)) {
        setOpen(true);
        setError('Photo name is invalid. Please try again.');
      } else if (
        newPhotoName.startsWith(`_`) ||
        newPhotoName.startsWith(`-`) ||
        newPhotoName.startsWith(`.`) ||
        newPhotoName.startsWith(` `) ||
        newPhotoName.endsWith(`_`) ||
        newPhotoName.endsWith(`-`) ||
        newPhotoName.endsWith(`.`) ||
        newPhotoName.endsWith(` `)
      ) {
        setOpen(true);
        setError('Photo name is invalid. Please try again.');
      } else {
        setOpen(false);
        file.fileName = newPhotoName;
        renamePhoto(file);
        setError(undefined);
      }
    }
  };

  return (
    <Card width='medium'>
      <Box align='center'>
        <Button onClick={() => onOpen()}>
          <Text size='small'>
            {file.fileName} <u style={{ color: '#8223D2' }}>(Rename)</u>
          </Text>
        </Button>
      </Box>
      {open && (
        <Layer position='center' modal onClickOutside={onClose} onEsc={onClose}>
          <Box as='form' overflow='auto' width='medium' pad='medium' onSubmit={onClose}>
            <Box align='center'>
              <Text size='medium'>Name your photo</Text>
            </Box>
            <Box pad='medium'>
              <Card>
                <CardBody>
                  {' '}
                  <Image
                    style={{
                      maxHeight: '80%',
                      maxWidth: '80%'
                    }}
                    src={file.fileContent}
                    align='center'
                    a11yTitle='file'
                  />
                </CardBody>
              </Card>
            </Box>
            <Box pad={{ vertical: 'medium' }}>
              <FormField label='Photo Name'>
                <TextInput value={newPhotoName} onChange={onChange} />
              </FormField>
              {error && (
                <Card background='accent-4' gap='small'>
                  <Box direction='row' align='center'>
                    <Text margin='small' size='medium'>
                      {error}
                    </Text>
                    <Button
                      plain
                      icon={<Close />}
                      onClick={() => {
                        setError(undefined);
                      }}
                      margin='small'
                    />
                  </Box>
                </Card>
              )}
            </Box>
            <Box as='footer' align='center' direction='row'>
              <Button type='submit' label='Save' onClick={onClose} primary />
              <Button label='Cancel' onClick={onCancel} />
            </Box>
          </Box>
        </Layer>
      )}
      <Box align='center'>
        <Image
          style={{
            maxHeight: '70%',
            maxWidth: '90%'
          }}
          fit='cover'
          src={file.fileContent}
          align='center'
          a11yTitle='file'
        />
      </Box>
      <Box align='center'>
        <Button onClick={() => deletePhoto(file.fileId)} plain>
          <Text color='#DB1B1B' size='xlarge'>
            <u>Delete Photo</u>
          </Text>
        </Button>
      </Box>
    </Card>
  );
};
