import React from 'react';

import { Box, Button, Heading, Image, Layer, List, Text } from 'grommet';
import camera from '../assets/Mobile camera.svg';
import landscape from '../assets/Lanscape polaroid.svg';

export const PhotoRequirement = () => {
  const [open, setOpen] = React.useState();
  const linkText = 'Photo requirements';
  const onOpen = () => setOpen(true);
  const onClose = () => setOpen(undefined);

  const list = [
    { image: camera, text: 'The camera on this device' },
    { image: '', text: 'or' },
    { image: landscape, text: 'Existing photos in JPG or PNG format, on this device' }
  ];

  return (
    <>
      <Button
        box-shadow='none'
        label={
          <Text color='#8223d2' size='xlarge'>
            {linkText}
          </Text>
        }
        onClick={onOpen}
        plain
      />
      {open && (
        <Layer position='center' onClickOutside={onClose} onEsc={onClose}>
          <Box pad='medium' gap='small' width='medium'>
            <Heading level={3} margin='none'>
              Photo requirements
            </Heading>
            <Text size='small'>You can use either:</Text>
            <List data={list} pad='small'>
              {datum => (
                <Box direction='row' gap='small'>
                  <Image src={datum.image} a11yTitle={datum.text}></Image>
                  <Text size='small'>{datum.text}</Text>
                </Box>
              )}
            </List>
            <Box as='footer' gap='small' direction='row' align='center' justify='end' pad={{ top: 'medium', bottom: 'small' }}>
              <Button label='Got it' onClick={onClose} primary />
            </Box>
          </Box>
        </Layer>
      )}
    </>
  );
};
