import React, { useContext } from 'react';

import { Box, Grid, ResponsiveContext } from 'grommet';

import { Photo } from './Photo';
import { GlobalContext } from '../context/GlobalState';

export const PhotoList = () => {
  const { files } = useContext(GlobalContext);
  const size = useContext(ResponsiveContext);
  return (
    <>
      {files.length > 0 && (
        <Box pad='small' align='center'>
          <Grid columns={size !== 'small' ? 'small' : '100%'} gap='small'>
            {files.map(file => (
              <Photo key={file.fileId} file={file} />
            ))}
          </Grid>
        </Box>
      )}
    </>
  );
};
