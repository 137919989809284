import React, { useRef, useState } from 'react';
import uuid from 'react-uuid';
import { decode as base64_decode } from 'base-64';
import { Grommet, Main, Text, Image, Box, List, Button, Header } from 'grommet';
import theme from './theme';
import './styles.css';

import { SBHeader } from './components/SBHeader';
import { SBFooter } from './components/SBFooter';
import { PhotoRequirement } from './components/PhotoRequirement';

import manWithCamera from './assets/Man with camera.svg';
import barCodeTag from './assets/barcode-tag.svg';
import damage from './assets/Damage.svg';
import other from './assets/Other.svg';
import config from '../src/config.json';

function StartPage(props) {
  const [showStartPage, setShowStartPage] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('Something went wrong—try again.');
  const willMount = useRef(true);

  const url = new URL(window.location.href);
  let id = url.searchParams.get('id');
  let extref = url.searchParams.get('extref');
  let spName = base64_decode(url.searchParams.get('spName'));
  let spContact = base64_decode(url.searchParams.get('spContact'));
  const useNote = url.searchParams.get('useNote');
  const correlationId = uuid();

  const title = useNote && useNote === 'true' ? 'Your service provider has requested photos/notes.' : 'Your service provider has requested photos.';
  const subtitle = 'It’s easy—you can take new photos or use existing ones.';
  const listHeader = 'We’re looking for these helpful photos';
  const list = [
    { image: barCodeTag, text: 'Model and serial no.' },
    { image: damage, text: 'Damage' },
    { image: other, text: 'Anything else to help identify the issue' }
  ];

  const note = 'If you’re unable to provide photos for any reason, contact ' + spName + ' at ';
  const alert = '🚨 To prevent delays, once you start the photo process, you must complete it.';
  const buttonLabel = 'Get Started';

  const onSubmit = () => {
    return props.history.push('/uploadPhotos/');
  };

  function getconfigObject(name) {
    const endpoint = config.API.endpoints.filter(ep => ep.name.toLowerCase() === name.toLowerCase());
    return endpoint;
  }

  function validateUrlRequest() {
    const configObject = getconfigObject('remoteviewer-content');
    //console.log(`${configObject[0].endpoint}${configObject[0].uri}`);
    //console.log(` ${configObject[0].apikey}`);
    let response = fetch(`${configObject[0].endpoint}${configObject[0].uri}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        correlationId: correlationId,
        sessionId: uuid(),
        username: 'servicebench',
        sender: 'sbappuser',
        companyId: 'system',
        'x-api-key': configObject[0].apikey
      },
      body: JSON.stringify({
        interactionId: id,
        externalReferenceId: extref
      })
    });
    return response;
  }

  async function validateLink() {
    console.log('id - ');
    console.log(id);
    console.log('extref - ');
    console.log(extref);
    if (!id || !extref || !spName || !spContact) {
      //console.log("the link used is not valid: no Id");
      setError(true);
      setShowStartPage(true);
      setErrorMessage('The link used is not valid.');
    } else {
      let response = await validateUrlRequest();
      console.log(`Response get for remote Viewer ${JSON.stringify(response)}`);
      //console.log("response.status: " + response.status);
      let output = (await response.json()).body;
      //console.log("response output: " + JSON.stringify(output, null, 2));
      let urlParams = '';
      if (url.searchParams.get('spName') && url.searchParams.get('spContact')) {
        urlParams = '?spName=' + url.searchParams.get('spName') + '&spContact=' + url.searchParams.get('spContact');
      }
      if (response.status === 200) {
        if (output.status === 'INVALID') {
          if (output.reason === 'ALREADY UPLOADED') {
            return props.history.push('/errorSent' + urlParams);
          } else if (output.reason === 'EXPIRED') {
            return props.history.push('/errorExpired' + urlParams);
          } else {
            setShowStartPage(true);
            setError(true);
          }
        } else if (output.status === 'VALID') {
          //setPassValidation(true);
          setShowStartPage(true);
        } else {
          setError(true);
          setShowStartPage(true);
        }
      } else {
        setError(true);
        setShowStartPage(true);
      }
    }
  }

  if (willMount.current) {
    validateLink();
    willMount.current = false;
  }

  return (
    <>
      {showStartPage && (
        <Grommet theme={theme}>
          <SBHeader />
          {error && (
            <Header background='accent-4' style={{ lineHeight: '1erm' }}>
              <Text margin='small' size='small'>
                {errorMessage}
              </Text>
            </Header>
          )}
          <Main>
            <Box align='center'>
              <Text margin='small' size='xlarge'>
                {title}
              </Text>
              <Text margin='small' size='medium'>
                {subtitle}
              </Text>
              <PhotoRequirement />
              <Image src={manWithCamera} a11yTitle='Man with camera'></Image>
              <Box align='start'>
                <Text margin='small' size='small'>
                  <strong>{listHeader}</strong>
                </Text>
              </Box>
              <List data={list} pad='small'>
                {datum => (
                  <Box direction='row' gap='small'>
                    <Image src={datum.image} a11yTitle={datum.text}></Image>
                    <Text size='small'>{datum.text}</Text>
                  </Box>
                )}
              </List>
              <Box style={{ borderRadius: 8, marginTop: 10 }} pad='small' gap='medium' background='accent-2'>
                <Text margin='small' size='small'>
                  {note}
                  <strong>
                    <u>{spContact}</u>
                  </strong>
                  .
                </Text>
              </Box>
              <Text pad='small' margin='small' size='small'>
                <strong>{alert}</strong>
              </Text>
              <Box align='center' pad='small'>
                <Button primary label={buttonLabel} onClick={onSubmit} disabled={error} />
              </Box>
            </Box>
          </Main>
          <SBFooter />
        </Grommet>
      )}
    </>
  );
}

export default StartPage;
