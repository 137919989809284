import React, { useContext, useRef, useState } from 'react';
import uuid from 'react-uuid';
import { GlobalContext } from '../context/GlobalState';
import { Close } from 'grommet-icons';
import { Box, Button, Image, Text, TextInput, Card, CardBody } from 'grommet';
import addCircle from '../assets/add-circle.svg';
import imageCompression from 'browser-image-compression';
import 'react-html5-camera-photo/build/css/index.css';

export const AddPhoto = () => {
  const [error, setError] = useState('');
  const { files, addPhoto } = useContext(GlobalContext);
  const fileInputRef = useRef(null);

  const openFileDialog = () => {
    fileInputRef.current.click();
  };

  const onFilesAdded = event => {
    let imageFile = event.target.files[0];
    let uploadError = '';
    setError('');

    // validation file type (only allow JPG and PNG files under 10MB)
    if (!imageFile.type.match(/(jpg|png|jpeg)$/)) {
      uploadError = 'We only accept JPG and PNG files. Choose a JPG or PNG file. ';
    }

    /*
        if (imageFile.size > 10000000) {
            uploadError = uploadError + "Your photo is too large. Choose one smaller than 10MB."
        }*/

    if (uploadError !== '') {
      setError(uploadError);
      return false;
    }

    //console.log('originalFile instanceof Blob', imageFile instanceof Blob); // true
    //console.log(`originalFile size ${imageFile.size / 1024 / 1024} MB`);
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true
    };
    imageCompression(imageFile, options)
      .then(function (compressedFile) {
        //console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
        //console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB
        const reader = new FileReader();
        reader.onload = e => {
          const img = e.target.result;
          const file = {
            fileId: uuid(),
            fileName: 'Remote-viewer-' + (files.length + 1),
            fileType: 'photo',
            fileExtension: compressedFile.type,
            fileContent: img
          };
          //console.log("file - ");
          //console.log(file);
          addPhoto(file);
        };
        if (compressedFile) {
          reader.readAsDataURL(compressedFile);
        }
      })
      .catch(function (error) {
        console.log(error.message);
      });
  };

  return (
    <>
      {error && (
        <Card background='accent-4' gap='small'>
          <Box direction='row' align='center'>
            <Text margin='small' size='medium'>
              {error}
            </Text>
            <Button
              plain
              icon={<Close />}
              onClick={() => {
                setError(undefined);
              }}
              margin='small'
            />
          </Box>
        </Card>
      )}
      {files.length < 5 && (
        <Box pad='small' align='center'>
          <Card align='center' width='medium' border={[{ size: 'small', style: 'dashed' }]}>
            <CardBody pad='large'>
              <Box align='center'>
                <Image src={addCircle} width='32' height='32' a11yTitle='Add circle'></Image>
              </Box>
              <Box>
                <Box direction='row' align='center'>
                  <Box justify='center' align='center' pad='small'>
                    <Button label='Add Photo' fill onClick={openFileDialog} />
                  </Box>
                </Box>
                <Box>
                  <TextInput a11yTitle='files' hidden ref={fileInputRef} type='file' multiple onChange={onFilesAdded} />
                </Box>
              </Box>
            </CardBody>
          </Card>
        </Box>
      )}
    </>
  );
};
