import React, { useContext } from 'react';

import { Box, Text, List, Button } from 'grommet';
import { Close } from 'grommet-icons';
import { GlobalContext } from '../context/GlobalState';

export const NoteList = () => {
  const { notes, deleteNote } = useContext(GlobalContext);
  return (
    <>
      {notes.length > 0 && (
        <List data={notes} pad='small'>
          {note => (
            <Box direction='row' gap='small'>
              <Button plain primary icon={<Close />} onClick={() => deleteNote(note.noteId)} />
              <Text width='medium'>{note.noteContent}</Text>
            </Box>
          )}
        </List>
      )}
    </>
  );
};
